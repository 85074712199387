import React from "react";
import Layout from "components/layout/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import SEO from "components/seo";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";

const DocumentPage = ({ pageContext, location }) => {
    return (
    <Layout locale={pageContext.item.frontmatter.language} location={location}>
        <div className="MainContent">
            <section className="TextBlock">
                <div className="container">
                    <div className="c20p">&nbsp;</div>
                    <div className="c80p">
                        <MDXRenderer>{pageContext.item.body}</MDXRenderer>
                    </div>
                </div>
            </section>
        </div>
        <SEO description={pageContext.item.frontmatter.description} title={pageContext.item.frontmatter.title} author={pageContext.item.frontmatter.author} />
    </Layout>
  );
};

export default DocumentPage;
